var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{staticClass:"pathModul",attrs:{"items":_vm.pathModul,"divider":"-","normal":""}}),_c('v-container',{class:_vm.$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1',attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"col-input-filter"},[_c('v-select',{attrs:{"items":_vm.itemStation,"label":"Station","prepend-inner-icon":"mdi-store-marker","solo":"","dense":"","hide-details":""},on:{"change":_vm.selectValueStation}})],1),_c('v-col',{staticClass:"col-input-filter"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of Maintenance","prepend-inner-icon":"mdi-calendar-today","readonly":"","solo":"","dense":"","hide-details":""},model:{value:(_vm.dateFormat),callback:function ($$v) {_vm.dateFormat=$$v},expression:"dateFormat"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalCalendar),callback:function ($$v) {_vm.modalCalendar=$$v},expression:"modalCalendar"}},[_c('v-date-picker',{on:{"change":_vm.selectDate,"input":function($event){_vm.modalCalendar = false}},model:{value:(_vm.dateFormat),callback:function ($$v) {_vm.dateFormat=$$v},expression:"dateFormat"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-file-input',{ref:"file",attrs:{"label":"File input","show-size":"","truncate-length":"15","id":"file","solo":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.handleFileUpload( $event )}}})],1),_c('v-col',{staticClass:"col-btn-generateReport",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{staticClass:"ma-2 white--text btn-upload button",attrs:{"solo":"","dense":"","normal":"","disabled":_vm.file == null || _vm.valStation == null},on:{"click":function($event){return _vm.submitFile()}}},[_vm._v(" SUBMIT ")]),_c('v-btn',{staticClass:"ma-2 white--text btn-upload button",attrs:{"solo":"","dense":"","normal":""},on:{"click":_vm.BackToMaintenanceRecord}},[_vm._v(" BACK ")])],1)],1),_c('v-card',{staticClass:"pa-4",staticStyle:{"max-height":"calc(100vh - 230px)","overflow-y":"auto"}},[_c('v-subheader',{staticClass:"subTitle fontSubTitle-blue"},[_vm._v(" Maintenance Record For "+_vm._s(_vm.stationID)+" ("+_vm._s(_vm.dateSelected)+") ")]),_c('v-data-table',{staticClass:"elevation-1 tableMaintenanceUpload headerDtSarawak",attrs:{"headers":_vm.thead_maintenance_upload,"items":_vm.tbody_maintenance_upload,"header-props":{ sortIcon: null },"items-per-page":5,"no-select-on-click":""},scopedSlots:_vm._u([{key:"item.datetimeUpload",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.datetimeUpload)}})]}},{key:"item.fileDoc",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"#02b2c3"},domProps:{"innerHTML":_vm._s(item.fileDoc)},on:{"click":function($event){return _vm.openFileDoc(item)}}})]}},{key:"item.delDetails",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"red"},domProps:{"innerHTML":_vm._s(item.delDetails)},on:{"click":function($event){return _vm.deleteItem(item)}}})]}}],null,true)}),[_c('div',{staticClass:"text-center",staticStyle:{"margin":"30px 0px"}},[_c('v-progress-circular',{attrs:{"id":"loadingUpload","rotate":360,"size":100,"width":15,"value":_vm.value,"color":"rgb(1 157 191)"}},[_vm._v(" "+_vm._s(_vm.value)+" ")])],1)],_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"display":"none"},attrs:{"id":"showSuccessUpload","color":"green lighten-1","dark":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-note-check-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5",staticStyle:{"background":"#106f79","color":"white"}},[_vm._v(" Upload Complete "),_c('v-icon',{staticStyle:{"color":"white","margin-left":"15px"}},[_vm._v("mdi-note-check-outline")])],1),_c('v-card-text',{staticStyle:{"padding":"20px 24px","font-weight":"bold"}},[_vm._v(" Your file were uploaded successfully ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#106f79","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)],2),_c('v-container',{staticClass:"container-upload-file form-uploadFile"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }